import React, { Component } from 'react' 

class Home extends Component{
  render (){
    return (
      <div className='home'>  
      <section className='home-container flex gap-5 justify-center mb-5'>
      {/* <img src='images/cover.png' alt='Jumper Ballads cover' className='cover-img' /> */}
        <img src='https://via.placeholder.com/300x450?text=project' alt='project' className='project-img border-2 border-indigo-600 w-1/3'/>
        <p className='blurb border-2 border-indigo-600 w-1/2'>This is the home page! Put some stuff about the book here, like a blurb. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Pellentesque sit amet porttitor eget dolor. Lectus arcu bibendum at varius vel pharetra vel turpis nunc. Nec feugiat nisl pretium fusce id velit ut tortor pretium. Nam libero justo laoreet sit amet cursus sit amet. Amet nulla facilisi morbi tempus iaculis urna id volutpat lacus. Vel quam elementum pulvinar etiam. Orci sagittis eu volutpat odio facilisis mauris sit amet massa. Nulla pharetra diam sit amet nisl suscipit adipiscing bibendum. Massa tempor nec feugiat nisl. Vel risus commodo viverra maecenas accumsan lacus vel facilisis volutpat. Congue eu consequat ac felis donec. Porttitor leo a diam sollicitudin tempor id eu. Vulputate eu scelerisque felis imperdiet.</p>
        
      </section>
      </div>
    )
  }
}

export default Home