import React, { Component } from 'react'
import Readmore from './readmore'
import '../styles/modal.css';

class Projects extends Component{


  render (){
    return (
      <div className='projects'>  


        <section className='home-container p-10 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-5'>

          <div className="rounded overflow-hidden shadow-lg cursor-pointer hover:shadow-md hover:border-opacity-0 transform hover:-translate-y-1 transition-all duration-200">
            <img src='https://via.placeholder.com/300x450?text=project' alt='project' className='border-2 border-indigo-600 w-full'/>
            <div className="px-6 py-4 border-2 border-indigo-600">
              <div className="text-xl mb-2">Title</div>
              <p className='blurb text-gray-700 text-base p-1'><Readmore>
                Click link to buy
                Project 1. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam at libero lacus. Suspendisse ac sem commodo diam semper porttitor et vel ex. Maecenas rhoncus justo quis tellus suscipit viverra. Donec tempus vel sapien et laoreet. Aenean imperdiet arcu nec auctor hendrerit. Proin faucibus est sit amet metus aliquet molestie. Nam rhoncus a dui id bibendum. Aenean a nisi eget felis suscipit laoreet. Proin at maximus odio. In lacinia a lectus nec vehicula.
                </Readmore></p>
            </div>
          </div>

          <div className="rounded overflow-hidden shadow-lg cursor-pointer hover:shadow-md hover:border-opacity-0 transform hover:-translate-y-1 transition-all duration-200">
            <img src='https://via.placeholder.com/300x450?text=project' alt='project' className='border-2 border-indigo-600 w-full'/>
            <div className="px-6 py-4 border-2 border-indigo-600">
              <div className="text-xl mb-2 container">Title</div>
              <p className='blurb text-gray-700 text-base p-1'>
                <Readmore>
                Click link to buy

                Project 2. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam at libero lacus. Suspendisse ac sem commodo diam semper porttitor et vel ex. Maecenas rhoncus justo quis tellus suscipit viverra. Donec tempus vel sapien et laoreet. Aenean imperdiet arcu nec auctor hendrerit. Proin faucibus est sit amet metus aliquet molestie. Nam rhoncus a dui id bibendum. Aenean a nisi eget felis suscipit laoreet. Proin at maximus odio. In lacinia a lectus nec vehicula.
                </Readmore>
              </p>


            </div>
          </div>

          <div className="rounded overflow-hidden shadow-lg cursor-pointer hover:shadow-md hover:border-opacity-0 transform hover:-translate-y-1 transition-all duration-200">
            <img src='https://via.placeholder.com/300x450?text=project' alt='project' className='border-2 border-indigo-600 w-full'/>
            <div className="px-6 py-4 border-2 border-indigo-600">
              <div className="text-xl mb-2">Title</div>
              <p className='blurb text-gray-700 text-base p-1'>
                <Readmore>
                Click link to buy

                Project 3. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam at libero lacus. Suspendisse ac sem commodo diam semper porttitor et vel ex. Maecenas rhoncus justo quis tellus suscipit viverra. Donec tempus vel sapien et laoreet. Aenean imperdiet arcu nec auctor hendrerit. Proin faucibus est sit amet metus aliquet molestie. Nam rhoncus a dui id bibendum. Aenean a nisi eget felis suscipit laoreet. Proin at maximus odio. In lacinia a lectus nec vehicula.
                </Readmore></p>   

            </div>
          </div>
          
    
          
        </section>
      </div>
    )
  }
}

export default Projects