import React, { Component } from 'react'

class Footer extends Component{
    render(){
        return(
            <footer className= "flex justify-center border-2 border-indigo-600 w-full mb-5">Website built by <a href="https://www.linkedin.com/in/aliya-summers/" className="mx-1"> Aliya Summers</a> </footer>
        )
    }

}

export default Footer;