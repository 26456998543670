import React, { Component } from 'react' 
import{
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import About from './about'
import Home from './home'
import Blog from './blog'
import Contact from './contact'
import Projects from './projects'

class Header extends Component{
    render(){
        return(
            <Router>
     <div className="container">
       <header>
       <img src='https://via.placeholder.com/1000x60?text=Shannon+Header+img' alt='Shannon header' className='long-header mx-auto mt-3 border-2 border-indigo-600' />
       <nav className='flex justify-center gap-10 mb-5 mt-5'>

         <ul classname="listy flex flex-row-reverse">
           
           <li className='navlink mr-5'>
             <Link to='/'>Home</Link>
           </li>
           <li className='navlink mr-5'>
             <Link to='/about'>About</Link>
           </li>
           <li className='navlink mr-5'>
             <Link to='/blog'>Blog</Link>
           </li>
           <li className='navlink mr-5'>
             <Link to='/projects'>Projects</Link>
           </li>
           <li className='navlink'>
             <Link to='/contact'>Contact</Link>
           </li>
         </ul>
       </nav>
       </header>
       {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}

        <Switch>
          <Route path='/about'>
            <AboutRoute />          
            </Route>

            <Route path='/blog'>
            <BlogRoute />
            </Route>

            <Route path='/contact'>
            <ContactRoute />
            </Route>
            
            <Route path='/projects'>
            <ProjectRoute />
            </Route>

            <Route path='/'>
            <HomeRoute />
            </Route>

        </Switch>
     </div>
   </Router>
            )
    }
       
}



function HomeRoute(){
    return <Home />;
  }
  
  function AboutRoute(){
    return <About />;
  }
  
  function BlogRoute(){
    return <Blog />;
  }
  
  function ContactRoute(){
    return <Contact />;
  }

  function ProjectRoute(){
    return <Projects />;
  }
export default Header;