import React from 'react'
import axios from 'axios'

export default class Blog extends React.Component{
  state = {
    blogPosts : []
  }

  componentDidMount(){
    axios.get('https://public-api.wordpress.com/rest/v1/sites/aliyasummers1.wordpress.com/posts')
      .then(response=> {
        const blogPosts = response.data.posts;
        this.setState({blogPosts})
      })
  }

  render(){
    return(
      <section>
        {this.state.blogPosts.map(blogPost => (
        <div className="bg-purple-400 p-6 rounded-lg shadow-lg mb-5 ml-5">
          <h1 className="text-2xl font-bold mb-2 text-gray-800">{blogPost.title}</h1> 

          <p className="text-gray-700"  dangerouslySetInnerHTML={{ __html: blogPost.content }}>
          </p>

          </div>))}
        
      </section>

    )

  }

}