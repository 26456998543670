import React from 'react';
import Header from './components/header'
import Footer from './components/footer'
import "tailwindcss/tailwind.css"

export default function App() {
  return (
  <div>
    <Header></Header>
    <Footer></Footer>
  </div>
  
  );
}
