import React, { Component } from 'react' 

class Contact extends Component{
  render (){
    return (
      <div className='contact'>  
      <section>
        <p className="mb-72 ml-5">For more information about the books, contact <a href="mailto:shannoncontact@email.com">shannoncontact@email.com</a></p>
      </section>
      </div>
    )
  }
}

export default Contact